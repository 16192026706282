* {
  box-sizing: border-box;
}
html {
  height: 100%;
  font-family: sans-serif;
  overscroll-behavior-y: contain;
  overflow-x: hidden;
}
body {
  margin: 0;
  height: 100%;
  overflow-x: hidden;
  overscroll-behavior-y: contain;
  padding: env(safe-area-inset-top) env(safe-area-inset-right)
    env(safe-area-inset-bottom) env(safe-area-inset-left);
  display: flex;
  justify-content: center;
}
#root {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  max-width: 1024px;
}
a {
  color: #2f305c;
  text-decoration: initial;
}

.SnackbarContainer-bottom {
  padding: env(safe-area-inset-top) env(safe-area-inset-right)
    env(safe-area-inset-bottom) env(safe-area-inset-left);
  bottom: 60px !important;
}

.SnackbarContainer-bottom button {
  color: white;
}

.SnackbarContainer-bottom button.deleteBtn {
  color: red;
}

.MuiDialogTitle-root {
  background: #2f305c;
  color: white;
  padding: 12px 24px !important;
}

.MuiDialogTitle-root .MuiGrid-root {
  display: flex;
  align-items: center;
  justify-content: center;
}

.MuiDialogTitle-root .MuiGrid-root .leftBtnGroup {
  position: absolute;
  left: 10px;
}

.MuiDialogTitle-root .MuiGrid-root .rightBtnGroup {
  position: absolute;
  right: 10px;
}

.MuiDialogTitle-root .MuiGrid-root .title {
  font-size: 18px;
}

.MuiDialogTitle-root button {
  color: white;
}
